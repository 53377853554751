import React, { Component } from "react";
import { Fade } from "react-reveal";

class Contact extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const message = this.props.data.contactmessage;

    return (
      <section id="contact">
        <Fade duration={1000}>
          <div className="row section-head">
            <div className="twelve columns header-col">
              <h1>
                Zapraszamy do kontaktu. 
              </h1>
            </div>

            <div className="ten columns">
              <p className="lead">{message}</p>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Fade duration={1000}>
            <div className="eight columns">
              <form action="" method="post" id="contactForm" name="contactForm">
                <fieldset>
                  <div>
                    <label htmlFor="contactName">
                      Imię i nazwisko <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactName"
                      name="contactName"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactEmail">
                      E-mail <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactEmail"
                      name="contactEmail"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactSubject">
                      Tytuł<span className="required">*</span>
                      </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactSubject"
                      name="contactSubject"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactMessage">
                      Wiadomość <span className="required">*</span>
                    </label>
                    <textarea
                      cols="50"
                      rows="15"
                      id="contactMessage"
                      name="contactMessage"
                    ></textarea>
                  </div>

                  <div>
                    <button className="submit">Wyślij</button>
                    <span id="image-loader">
                      <img alt="" src="images/loader.gif" />
                    </span>
                  </div>
                </fieldset>
              </form>

              <div id="message-warning"> Błąd!</div>
              <div id="message-success">
                <i className="fa fa-check"></i>Twoja wiadomość została wysłana, dziękujemy!
                <br />
              </div>
            </div>
          </Fade>

          <Fade duration={1000}>
            <aside className="four columns footer-widgets">
              <div className="widget widget_contact">
                
                <h4>Dane kontaktowe:</h4>
                <p>
                  SERWIS AQUA S.C.
                  <br/> A. ROKOWSKA, A. BIZOŃ
                  <br />
                  {zip} {city} {street}
                  <br />
                  <span>NIP 5512644140 </span>|<span> REGON 386016157 </span>< br/>
                  <span>telefon: +48 518 659 974 </span>< br/>
                  <span>e-mail: biuro@serwisaqua.pl</span><br/>
                  <span>Nr konta bankowego:</span><br/>
                  <span>21 1050 1100 1000 0092 7722 4441</span><br/>
                </p>
              </div>

              <div className="widget widget_tweets">
                <h4 className="widget-title">Nasze certyfikaty:</h4>
                <ul id="twitter">
                  <li>
                    <span>
                      <img
                        src="certyfikat1.png"
                        alt="Nordic Giant Profile Pic"
                      />
                    </span>
                  </li>
                  <li>
                    <span>
                    <img
                        src="certyfikat2.png"
                        alt="Nordic Giant Profile Pic"
                      />
                    </span>
                  </li>
                </ul>
              </div>
            </aside>
          </Fade>
        </div>
      </section>
    );
  }
}

export default Contact;
